import React from "react";
import PropTypes from "prop-types";
import { BoxIconWrap, BoxIconMarker, BoxIconInfo, BoxIconHeading, BoxIconTitle, BoxIconDesc, BoxIconBtn } from './box-icon.style'
import Button from "@ui/button";
import { Link } from "gatsby";


const BoxIcon = ({ icon, path, title, desc, heading, ...restProps }) => {
	const { wrapperStyle, iconStyle, infoStyle, titleStyle, descStyle, headingStyle, buttonStyles } = restProps;

	return (
		<BoxIconWrap {...wrapperStyle}>
			{icon && (
				<BoxIconMarker {...iconStyle}>
					<i className={icon}></i>
				</BoxIconMarker>
			)}
			{(title || desc) && (
				<BoxIconInfo {...infoStyle}>
					{heading && <BoxIconHeading {...headingStyle}>{heading}</BoxIconHeading>}
					{title && <BoxIconTitle {...titleStyle}>{title}</BoxIconTitle>}
					{desc && <BoxIconDesc {...descStyle}>{desc}</BoxIconDesc>}
					<BoxIconBtn>
						<Button to={path} as={Link} {...buttonStyles} icon="far fa-long-arrow-right">
							Discover now
                        </Button>
					</BoxIconBtn>
				</BoxIconInfo>
			)}
		</BoxIconWrap>
	)
}

BoxIcon.propTypes = {
	wrapperStyle: PropTypes.object,
	iconStyle: PropTypes.object,
	infoStyle: PropTypes.object,
	titleStyle: PropTypes.object,
	descStyle: PropTypes.object,
	headingStyle: PropTypes.object,
	buttonStyles: PropTypes.object

}


BoxIcon.defaultProps = {
	buttonStyles: {
		varient: 'texted',
		fontWeight: 400,
		hover: "false",
		icondistance: "8px"
	},
	path: "/"
}


export default BoxIcon;